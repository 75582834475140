<template>
  <div class="vigilancia-dispositivo">
    <div class="popup-row">
      <label>Nombre de dispositivo</label>
      <div class="input input-primary" :class="{ 'input-error': errorNombre }">
        <input type="text" placeholder="Nombrar dispositivo" v-model="nombre" />
      </div>
    </div>
    <div class="popup-row">
      <label class="popup-label">Hash</label>
      <label class="vigilancia-dispositivo-hash">{{ dispositivo.hash }}</label>
    </div>
    <button class="button button-primary" @click="aprobarDispositivo()">
      Aprobar
    </button>
  </div>
</template>

<script>
export default {
  name: "Dispositivo_Vigilancia",
  components: {},
  props: {
    dispositivo: Object,
  },
  data() {
    return {
      nombre: "",
      errorNombre: false,
    };
  },
  mounted() {},
  methods: {
    aprobarDispositivo() {
      if (this.nombre != null && this.nombre != "") {
        this.errorNombre = false;
        let json = {
          dispositivo: this.dispositivo,
          nombre: this.nombre,
        };
        this.$emit("aprobarDispositivo", json);
      } else {
        this.errorNombre = true;
      }
    },
  },
};
</script>

<style scoped lang="scss" src="@/assets/css/views/secciones.scss"></style>
<style
  scoped
  lang="scss"
  src="@/assets/css/views/admin/vigilancias.scss"
></style>

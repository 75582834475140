<template>
  <div class="popup-container">
    <div class="popup-mask"></div>
    <div class="popup">
      <div class="popup-top">
        <label>Info Vigilancia</label>
        <img src="../../../assets/img/icon/close_popup.png" @click="cerrar()" />
        <div class="popup-top-btn-container">
          <div
            class="popup-top-btn"
            @click="mostrarEliminarVigilancia = true"
            v-if="usuario != null && usuario.rol.some((r) => r == 'admin')"
          >
            <img src="../../../assets/img/popup/delete.png" />
          </div>
          <div
            class="popup-top-btn"
            @click="editar()"
            v-if="usuario != null && usuario.rol.some((r) => r == 'admin')"
          >
            <img src="../../../assets/img/popup/edit.png" />
          </div>
        </div>
      </div>
      <div class="popup-body popup-body-scroll">
        <div class="popup-row">
          <label class="popup-label-info">Empresa</label>
          <label>{{ vigilancia.empresa.nombre }}</label>
        </div>
        <div class="popup-row">
          <label class="popup-label-info">Nombre de vigilancia</label>
          <label>{{ vigilancia.nombre }}</label>
        </div>
        <div class="popup-row">
          <label class="popup-label-info">Dirección de vigilancia</label>
          <label>{{ vigilancia.direccion }}</label>
        </div>
        <div class="popup-row-double">
          <div class="popup-row">
            <label class="popup-label-info">Usuario de dispositivo</label>
            <label>{{ vigilancia.user_vigilancia.username }}</label>
          </div>
          <div class="popup-row">
            <label class="popup-label-info">Contraseña ingreso PC</label>
            <label>{{ vigilancia.password_ingreso }}</label>
          </div>
        </div>
        <div class="vigilancia-dispositivos">
          <div
            v-for="dispositivo in vigilancia.dispositivos"
            :key="dispositivo.id"
            class="vigilancia-dispositivo"
            v-show="dispositivo.activado == true"
          >
            <div v-show="dispositivo.activado == true">
              <label class="vigilancia-dispositivo-nombre">{{
                dispositivo.nombre
              }}</label>
              <img
                class="vigilancia-dispositivo-verificado"
                src="../../../assets/img/icon/verificado.png"
              />
              <img
                class="vigilancia-dispositivo-delete"
                src="../../../assets/img/popup/delete.png"
                @click="eliminarDispositivo(dispositivo)"
              />
              <label class="vigilancia-dispositivo-hash-titulo">Hash</label>
              <label class="vigilancia-dispositivo-hash">{{
                dispositivo.hash
              }}</label>
            </div>
          </div>
          <div v-if="usuario != null && usuario.rol.some((r) => r == 'admin')">
            <Dispositivo
              v-for="dispositivo in dispositivosNuevos"
              :key="dispositivo.id"
              :dispositivo="dispositivo"
              @aprobarDispositivo="aprobarDispositivo"
            ></Dispositivo>
          </div>
        </div>
      </div>
    </div>
    <Eliminar
      :mostrar="mostrarEliminarVigilancia"
      :tipo="'Vigilancia'"
      :nombre="vigilancia.nombre"
      @cerrar="eliminarVigilanciaCerrar()"
      @aceptar="eliminarVigilanciaAceptar()"
    ></Eliminar>
    <Eliminar
      :mostrar="mostrarEliminarDispositivo"
      :tipo="'Dispositivo'"
      :nombre="dispositivoElegido != null ? dispositivoElegido.nombre : null"
      @cerrar="eliminarDispositivoCerrar()"
      @aceptar="eliminarDispositivoAceptar()"
    ></Eliminar>
  </div>
</template>

<script>
import Dispositivo from "@/components/admin/vigilancias/Dispositivo.vue";
import Eliminar from "@/components/Eliminar.vue";
import { mapGetters } from "vuex";

export default {
  name: "Info_Vigilancia",
  components: { Dispositivo, Eliminar },
  props: {
    vigilancia: Object,
    dispositivosNuevos: Array,
  },
  data() {
    return {
      mostrarEliminarVigilancia: false,
      mostrarEliminarDispositivo: false,
      dispositivoElegido: null,
    };
  },
  computed: {
    ...mapGetters(["usuario"]),
  },
  mounted() {},
  methods: {
    editar() {
      this.$emit("editar", this.vigilancia);
      this.cerrar();
    },
    cerrar() {
      this.$emit("cerrar");
    },
    aprobarDispositivo(payload) {
      this.$emit("aprobarDispositivo", payload);
    },
    eliminarDispositivo(dispositivo) {
      this.dispositivoElegido = dispositivo;
      this.mostrarEliminarDispositivo = true;
    },
    eliminarVigilanciaCerrar() {
      this.mostrarEliminarVigilancia = false;
    },
    eliminarVigilanciaAceptar() {
      this.mostrarEliminarVigilancia = false;
      var that = this;
      this.$axios
        .delete(
          this.$serverURL + "/api/vigilancias/" + this.vigilancia.id + "/"
        )
        .then(function (response) {
          console.log(response);
          that.$emit("get");
          that.cerrar();
        })
        .catch(function (response) {
          console.log(response);
        });
    },
    eliminarDispositivoCerrar() {
      this.mostrarEliminarDispositivo = false;
    },
    eliminarDispositivoAceptar() {
      this.mostrarEliminarDispositivo = false;
      var that = this;
      this.$axios
        .delete(
          this.$serverURL +
            "/api/dispositivos/" +
            this.dispositivoElegido.id +
            "/"
        )
        .then(function (response) {
          console.log(response);
          that.vigilancia.dispositivos = that.vigilancia.dispositivos.filter(
            (d) => d.id != that.dispositivoElegido.id
          );
        })
        .catch(function (response) {
          console.log(response);
        });
    },
  },
};
</script>

<style scoped lang="scss" src="@/assets/css/views/secciones.scss">
</style>
<style
  scoped
  lang="scss"
  src="@/assets/css/views/admin/vigilancias.scss"
>
</style>

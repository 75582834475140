<template>
  <div class="popup-container">
    <div class="popup-mask"></div>
    <div class="popup">
      <div class="popup-top">
        <label>{{ titulo }}</label>
        <img
          src="../../../assets/img/icon/close_popup.png"
          @click="mostrarSalir = true"
        />
      </div>
      <div class="popup-body popup-body-scroll">
        <div class="popup-row">
          <label>Empresa (*)</label>
          <Dropdown
            class="select"
            :options="empresas"
            :placeholder="'Selecciona empresa'"
            :paraArriba="false"
            :conInput="false"
            :name="'nombre'"
            ref="dropdownEmpresa"
            v-on:updateOption="updateDropdownEmpresa"
            :class="{ 'error-dropdown': errorEmpresa }"
          ></Dropdown>
        </div>
        <div class="popup-row">
          <label>Nombre de vigilancia (*)</label>
          <div
            class="input input-primary"
            :class="{ 'input-error': errorNombre }"
          >
            <input
              type="text"
              placeholder="Escribe nombre vigilancia"
              v-model="nombre"
            />
          </div>
        </div>
        <div class="popup-row">
          <label>Dirección (*)</label>
          <div
            class="input input-primary"
            :class="{ 'input-error': errorDireccion }"
          >
            <input
              type="text"
              placeholder="Escribe dirección de la vigilancia"
              v-model="direccion"
            />
          </div>
        </div>
        <div class="popup-row">
          <label>Usuario para vincular dispositivo (*)</label>
          <div
            class="input input-primary"
            :class="{ 'input-error': errorUsuario }"
          >
            <input
              type="text"
              placeholder="Escribe usuario"
              v-model="usuarioElegido"
            />
          </div>
        </div>
        <div class="popup-row">
          <label>Contraseña para ingreso PC (*)</label>
          <div
            class="input input-primary"
            :class="{ 'input-error': errorPassword }"
          >
            <input
              type="text"
              placeholder="Escribe contraseña"
              v-model="password"
            />
          </div>
        </div>
      </div>
      <div class="popup-bot-border">
        <button
          v-show="!guardando"
          class="button button-primary popup-btn-continuar-chico"
          @click="finalizar()"
        >
          Finalizar
        </button>
        <button
          v-show="guardando"
          class="button button-ok popup-btn-continuar-chico"
        >
          Finalizando
        </button>
      </div>
    </div>
    <Salir
      :mostrar="mostrarSalir"
      :tipo="titulo.toLowerCase()"
      @cerrar="mostrarSalir = false"
      @aceptar="cerrar()"
    >
    </Salir>
  </div>
</template>

<script>
import Dropdown from "@/components/Dropdown.vue";
import Salir from "@/components/Salir.vue";

export default {
  name: "Nueva_Vigilancia",
  components: { Dropdown, Salir },
  data() {
    return {
      titulo: "Crear nueva vigilancia",
      empresas: [],
      dispositivos: [],
      id: null,
      empresaElegida: null,
      nombre: "",
      direccion: "",
      usuarioElegido: "",
      usuarioElegidoId: null,
      password: "",
      errorEmpresa: false,
      errorNombre: false,
      errorDireccion: false,
      errorUsuario: "",
      errorPassword: false,
      guardando: false,
      mostrarSalir: false,
    };
  },
  mounted() {
    this.$emitter.on("vigilancias-editar", (usuario) => this.editar(usuario));
    this.getEmpresas();
  },
  beforeUnmount() {
    this.$emitter.off("vigilancias-editar");
  },
  methods: {
    updateDropdownEmpresa(payload) {
      this.empresaElegida = payload;
    },
    getEmpresas() {
      var that = this;
      this.$axios
        .get(this.$serverURL + "/api/empresas/all/")
        .then(function (response) {
          console.log("empresas", response);
          that.empresas = response.data;
        })
        .catch(function (response) {
          console.log(response);
        });
    },
    editar(vigilancia) {
      this.titulo = "Editar vigilancia";
      this.id = vigilancia.id;
      this.empresaElegida = this.empresas.find(
        (e) => e.id == vigilancia.empresa.id
      );
      this.usuarioElegido = vigilancia.user_vigilancia.username;
      this.usuarioElegidoId = vigilancia.user_vigilancia.id;
      this.nombre = vigilancia.nombre;
      this.direccion = vigilancia.direccion;
      this.password = vigilancia.password_ingreso;
      var that = this;
      setTimeout(() => {
        that.$refs.dropdownEmpresa.selectedOption = that.empresaElegida;
        that.$refs.dropdownEmpresa.$refs.dropdownInput2.value =
          that.empresaElegida.nombre;
      }, 150);
      this.dispositivos = vigilancia.dispositivos;
    },
    finalizar() {
      this.guardando = true;
      let continuar = true;

      if (this.empresaElegida == null) {
        this.errorEmpresa = true;
        continuar = false;
      } else {
        this.errorEmpresa = false;
      }

      if (this.nombre == "") {
        this.errorNombre = true;
        continuar = false;
      } else {
        this.errorNombre = false;
      }

      if (this.direccion == "") {
        this.errorDireccion = true;
        continuar = false;
      } else {
        this.errorDireccion = false;
      }

      if (this.usuarioElegido == "") {
        this.errorUsuario = true;
        continuar = false;
      } else {
        this.errorUsuario = false;
      }

      if (this.password == "") {
        this.errorPassword = true;
        continuar = false;
      } else {
        this.errorPassword = false;
      }

      if (continuar) {
        let json = {
          id: this.id,
          empresa: this.empresaElegida.id,
          nombre: this.nombre,
          direccion: this.direccion,
          username: this.usuarioElegido,
          password_ingreso: this.password,
          dispositivos: this.dispositivos.map((d) => d.id),
        };
        var that = this;
        if (this.id != null) {
          json.user_vigilancia = this.usuarioElegidoId;
          this.$axios
            .put(this.$serverURL + "/api/vigilancias/" + this.id + "/", json)
            .then(function (response) {
              console.log(response);
              that.guardando = false;
              that.$emit("get");
              that.cerrar();
            })
            .catch(function (response) {
              console.log(response);
              that.guardando = false;
              that.$emit("get");
              Object.values(response.response.data).forEach((d) =>
                that.$emitter.emit("toast-error", d.length == 1 ? d[0] : d)
              );
            });
        } else {
          console.log(json);
          this.$axios
            .post(this.$serverURL + "/api/vigilancias/", json)
            .then(function (response) {
              console.log(response);
              that.guardando = false;
              that.$emit("get");
              that.cerrar();
            })
            .catch(function (response) {
              console.log(response);
              console.log(response.response.data);
              that.guardando = false;
              that.$emit("get");
              Object.values(response.response.data).forEach((d) =>
                that.$emitter.emit("toast-error", d.length == 1 ? d[0] : d)
              );
            });
        }
      } else {
        this.guardando = false;
      }
    },
    cerrar() {
      this.$emit("cerrar");
      this.titulo = "Crear nueva vigilancia";
      this.limpiar();
      this.seccion = 1;
      this.mostrarSalir = false;
    },
    limpiar() {
      this.empresaElegida = null;
      this.usuarioElegido = "";
      this.nombre = "";
      this.direccion = "";
      this.password = "";
      this.$refs.dropdownEmpresa.selectedOption = null;
      this.$refs.dropdownEmpresa.$refs.dropdownInput2.value = "";
      this.$refs.dropdownEmpresa.placeholderText = "Selecciona empresa";
      this.id = null;
      this.dispositivos = [];
    },
  },
};
</script>

<style scoped lang="scss" src="@/assets/css/views/secciones.scss">
</style>

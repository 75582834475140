<template>
  <div class="view">
    <Topbar :seccion="'vigilancias'"></Topbar>
    <div class="view-container">
      <div class="secciones-top">
        <label class="secciones-title">Vigilancias</label>
        <Dropdown
          class="dropdown"
          :options="empresas"
          :placeholder="'Todas las empresas'"
          :paraArriba="false"
          :conInput="false"
          :name="'nombre'"
          v-on:updateOption="updateDropdownEmpresa"
        ></Dropdown>
        <div
          class="secciones-buscar"
          :class="{ 'secciones-buscar-active': searchText != '' }"
        >
          <input
            type="text"
            placeholder="Buscar"
            v-model="searchText"
            @keyup="filtrar()"
          />
          <img
            class="secciones-buscar-close"
            src="../../assets/img/icon/close.png"
            @click="resetBuscar()"
          />
          <img
            class="secciones-buscar-img"
            src="../../assets/img/icon/lupa.png"
          />
        </div>
      </div>
      <div v-if="usuario != null && usuario.rol.some((r) => r == 'admin')">
        <div
          class="vigilancias-dispositivos"
          v-for="(dispositivo, index) in dispositivosNuevos"
          :key="index"
        >
          <img src="../../assets/img/icon/celular.png" />
          <div>
            <label class="vigilancias-titulo">Dispositivo Nuevo</label>
            <label class="vigilancias-subtitulo"
              >Se ha enviado solicitud de dispositivo nuevo desde vigilancia
              {{ dispositivo.vigilancia.nombre }}</label
            >
            <button
              class="button button-primary"
              style="float: right"
              @click="elegirVigilancia(dispositivo.vigilancia)"
            >
              Aprobar
            </button>
          </div>
        </div>
      </div>
      <div class="secciones-vigilancias">
        <div
          v-for="vigilancia in vigilanciasFiltradas"
          :key="vigilancia.id"
          class="vigilancias-lugar"
          @click="elegirVigilancia(vigilancia)"
        >
          <img class="lugar-img" src="../../assets/img/icon/lugar.png" />
          <div>
            <label class="lugar-nombre">{{ vigilancia.nombre }}</label>
            <label class="lugar-direccion">{{ vigilancia.direccion }}</label>
            <label class="lugar-dispositivos"
              >{{ vigilancia.dispositivos.length }} Dispositivos</label
            >
          </div>
        </div>
      </div>
      <button
        class="button button-primary secciones-crear-btn"
        @click="mostrarNuevo = true"
        v-if="usuario != null && usuario.rol.some((r) => r == 'admin')"
      >
        <img src="../../assets/img/icon/lugar_white.png" />Crear nueva
        Vigilancia
      </button>
    </div>
    <Nuevo
      v-show="mostrarNuevo"
      @cerrar="cerrarNuevo()"
      @get="getVigilancias()"
    ></Nuevo>
    <Info
      v-if="mostrarInfo"
      :vigilancia="vigilanciaElegida"
      :dispositivosNuevos="dispositivosVigilancia"
      @cerrar="cerrarInfo()"
      @editar="editar"
      @aprobarDispositivo="aprobarDispositivo"
      @get="getVigilancias()"
    ></Info>
  </div>
</template>

<script>
import Topbar from "@/components/Topbar.vue";
import Dropdown from "@/components/Dropdown.vue";
import Nuevo from "@/components/admin/vigilancias/Nuevo.vue";
import Info from "@/components/admin/vigilancias/Info.vue";
import { mapGetters } from "vuex";

export default {
  name: "Vigilancias",
  components: { Topbar, Dropdown, Nuevo, Info },
  data() {
    return {
      empresas: [],
      searchText: "",
      vigilancias: [],
      vigilanciasFiltradas: [],
      dispositivosNuevos: [],
      dispositivosVigilancia: [],
      mostrarNuevo: false,
      mostrarInfo: false,
      vigilanciaElegida: null,
    };
  },
  computed: {
    ...mapGetters(["usuario"]),
  },
  mounted() {
    this.getVigilancias();
    this.getEmpresas();
    this.getDispositivosNuevos();
  },
  methods: {
    updateDropdownEmpresa(payload) {
      if (payload.nombre != "Todas") {
        this.vigilanciasFiltradas = this.vigilancias.filter(
          (v) => v.empresa.id == payload.id
        );
      } else {
        this.vigilanciasFiltradas = this.vigilancias;
      }
    },
    cerrarNuevo() {
      this.mostrarNuevo = false;
    },
    cerrarInfo() {
      this.mostrarInfo = false;
    },
    elegirVigilancia(lugar) {
      this.vigilanciaElegida = lugar;
      this.dispositivosVigilancia = this.dispositivosNuevos.filter(
        (d) => d.vigilancia.id == lugar.id
      );
      this.mostrarInfo = true;
    },
    editar(usuario) {
      this.mostrarNuevo = true;
      this.$emitter.emit("vigilancias-editar", usuario);
    },
    getVigilancias() {
      var that = this;
      this.$axios
        .get(this.$serverURL + "/api/vigilancias/all/")
        .then(function (response) {
          console.log("vigilancias", response);
          that.vigilancias = response.data;
          that.vigilanciasFiltradas = that.vigilancias;
        })
        .catch(function (response) {
          console.log(response);
        });
    },
    getEmpresas() {
      var that = this;
      this.$axios
        .get(this.$serverURL + "/api/empresas/all/")
        .then(function (response) {
          console.log("empresas", response);
          that.empresas = response.data;
          that.empresas.push({ nombre: "Todas" });
        })
        .catch(function (response) {
          console.log(response);
        });
    },
    getDispositivosNuevos() {
      var that = this;
      this.$axios
        .get(this.$serverURL + "/api/dispositivos/solicitudes/")
        .then(function (response) {
          console.log("dispositivosNuevos", response);
          that.dispositivosNuevos = response.data;
        })
        .catch(function (response) {
          console.log(response);
        });
    },
    aprobarDispositivo(payload) {
      payload.dispositivo.nombre = payload.nombre;
      this.dispositivosVigilancia = this.dispositivosVigilancia.filter(
        (d) => d.id != payload.dispositivo.id
      );
      this.dispositivosNuevos = this.dispositivosNuevos.filter(
        (d) => d.id != payload.dispositivo.id
      );
      this.vigilanciaElegida.dispositivos =
        this.vigilanciaElegida.dispositivos.filter(
          (d) => d.id != payload.dispositivo.id
        );
      this.aprobarDispositivoFinal(payload.dispositivo);
    },
    aprobarDispositivoFinal(dispositivo) {
      dispositivo.activado = true;
      dispositivo.vigilancia = dispositivo.vigilancia.id;
      var that = this;
      this.$axios
        .put(
          this.$serverURL +
            "/api/dispositivos/solicitudes/" +
            dispositivo.id +
            "/",
          dispositivo
        )
        .then(function (response) {
          console.log("aprobarDispositivo", response);
          that.vigilanciaElegida.dispositivos.push(dispositivo);
        })
        .catch(function (response) {
          console.log(response);
        });
    },
    resetBuscar() {
      this.searchText = "";
      this.filtrar();
    },
    filtrar() {
      if (this.searchText == "") {
        this.getVigilancias();
      } else {
        var that = this;
        this.$axios
          .get(
            this.$serverURL + "/api/vigilancias/search/" + this.searchText + "/"
          )
          .then(function (response) {
            console.log("vigilancias search", response);
            that.vigilancias = response.data;
            that.vigilanciasFiltradas = that.vigilancias;
          })
          .catch(function (response) {
            console.log(response);
          });
      }
    },
  },
};
</script>

<style scoped lang="scss" src="@/assets/css/views/secciones.scss">
</style>
<style
  scoped
  lang="scss"
  src="@/assets/css/views/admin/vigilancias.scss"
>
</style>
